import React, { useContext, useState } from "react";
import { HorizontalNav } from "../components/HorizontalNav";
import { Question } from "../components/Question";
import "./MainPage.css";
import { GivenQuestions, Phases, QuestionType } from "../constants/Questions";
import { ResultContext } from "../ResultContext";
import { FiArrowLeft, FiArrowRight } from "react-icons/fi";
import { SingleInput } from "../inputs/SingleInput";
import { SelectionInput } from "../inputs/SelectionInput";

export function MainPage() {
  const { result, setResult } = useContext(ResultContext);
  const [selectedQuestion, setSelectedQuestion] = useState(0);

  const sortedPhases = Phases.sort((a, b) => a.position - b.position);
  const sortedGivenQuestions = GivenQuestions.sort(
    (a, b) => a.position - b.position
  );

  const foundQuestion = sortedGivenQuestions[selectedQuestion];
  const foundPhase = sortedPhases.find((p) => p.id === foundQuestion.phase);

  const finalPhase = foundPhase
    ? foundPhase.position === Phases.length - 1
    : false;

  function goNext() {
    setSelectedQuestion(selectedQuestion + 1);
  }

  function goBack() {
    setSelectedQuestion(selectedQuestion - 1);
  }

  if (!foundPhase) {
    return null;
  }

  if (!foundQuestion) {
    return null;
  }

  return (
    <div className="main flex flex-column">
      <HorizontalNav
        selectedPhase={foundPhase.id}
        setSelectedPhase={(p) => {
          const questionsInPhase = sortedGivenQuestions.filter(
            (q) => q.phase === p
          );
          setSelectedQuestion(questionsInPhase[0].position);
        }}
      />
      <div className="flex content flex-column">
        {foundQuestion ? <Question title={foundQuestion.question} /> : null}
        <div className="flex w-100">
          <div className="flex w-100 flex-column">
            <div className="question-container">
              {foundQuestion &&
              foundQuestion.questionType === QuestionType.SingleInput ? (
                <SingleInput
                  questionId={foundQuestion.id}
                  label={foundQuestion.label}
                />
              ) : null}
              {foundQuestion &&
              foundQuestion.questionType === QuestionType.SelectionInput ? (
                <SelectionInput
                  questionId={foundQuestion.id}
                  label={foundQuestion.label}
                />
              ) : null}
            </div>
            <div className="ml-auto flex">
              {selectedQuestion === 0 ? null : (
                <div
                  className="button fixed back flex items-center"
                  onClick={() => {
                    if (!foundPhase) {
                      return;
                    }

                    goBack();
                  }}
                >
                  <FiArrowLeft />
                  <div className="ml-2">Back</div>
                </div>
              )}
              {finalPhase ? null : (
                <div
                  className="button fixed next flex items-center ml-3"
                  onClick={() => {
                    if (!foundPhase) {
                      return;
                    }

                    goNext();
                  }}
                >
                  <div className="mr-2">Next</div>
                  <FiArrowRight />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
