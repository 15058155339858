import React, { useContext, useEffect, useState } from "react";
import { ResultContext } from "../ResultContext";
import { Label } from "../components/Label";
import { GivenQuestions } from "../constants/Questions";
import "./SelectionInput.css";

export function SelectionInput({
  questionId,
  label,
}: {
  questionId: number;
  label: string | undefined;
}) {
  const { result, setResult } = useContext(ResultContext);
  const [search, setSearch] = useState("");

  const foundQuestion = GivenQuestions.find((q) => q.id === questionId);
  const foundResult = (result[questionId] as string) || "";

  useEffect(() => {
    if (!result[questionId]) {
      const copy = { ...result };

      copy[questionId] = "";

      setResult(copy);
    }
    // eslint-disable-next-line
  }, [questionId]);

  if (!foundQuestion) {
    return null;
  }

  return (
    <div className="flex flex-column">
      <Label title={label || ""} information={<></>} />
      <div className="selection-items">
        {foundQuestion.options
          ? foundQuestion.options
              .filter((o) =>
                o.toLowerCase().trim().includes(search.toLowerCase().trim())
              )
              .slice(0, 6)
              .map((item) => {
                return (
                  <div
                    className={`selection-item ${
                      foundResult === item ? "selected" : ""
                    }`}
                    onClick={() => {
                      const copy = { ...result };

                      copy[questionId] = item;

                      setResult(copy);
                    }}
                  >
                    {item}
                  </div>
                );
              })
          : null}
      </div>
      <input
        type="text"
        placeholder={"Zoeken..."}
        value={search}
        onChange={(e) => {
          setSearch(e.target.value);
        }}
      />
      {foundResult !== "" ? (
        <div>
          <div>Geselecteerd:</div>
          <div className="selected-item mt-4">{foundResult}</div>
        </div>
      ) : null}
    </div>
  );
}
