import { PropsWithChildren } from "react";
import ReactModal from "react-modal";
import "./Dialog.css";
import { AiOutlineClose } from "react-icons/ai";

export default function Dialog({
  isOpen,
  children,
  onRequestClose,
  title,
  size,
  padding,
  shouldCloseOnOverlayClick = true,
  shouldCloseOnEsc = true,
}: PropsWithChildren<{
  isOpen: boolean;
  onRequestClose: () => void;
  shouldCloseOnOverlayClick?: boolean;
  shouldCloseOnEsc?: boolean;
  title: string;
  size: "small" | "wide" | "xsmall" | "large" | "slide";
  padding: boolean;
}>) {
  const classNames = ["dialog-body"];
  if (size === "small") {
    classNames.push("is-small");
  }
  if (size === "wide") {
    classNames.push("is-wide");
  }
  if (size === "slide") {
    classNames.push("is-slide");
  }
  if (size === "xsmall") {
    classNames.push("is-xsmall");
  }
  if (size === "large") {
    classNames.push("is-large");
  }
  if (padding) {
    classNames.push("dialog-padding");
  }

  return (
    <ReactModal
      isOpen={isOpen}
      appElement={window.document.getElementById("root") as HTMLElement}
      onRequestClose={onRequestClose}
      className={classNames.join(" ")}
      overlayClassName="dialog-overlay"
      bodyOpenClassName="dialog-open"
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      shouldCloseOnEsc={shouldCloseOnEsc}
    >
      <div
        className={"dialog-header items-center " + (padding ? "mb-4" : "p-8")}
      >
        <div className="flex items-center">
          <div className="dialog-title">{title}</div>
        </div>
        <div
          className="close-button ml-auto"
          title="Close dialog"
          onClick={() => {
            onRequestClose();
          }}
        >
          <AiOutlineClose />
        </div>
      </div>
      {isOpen ? children : null}
    </ReactModal>
  );
}
