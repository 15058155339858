import React from "react";
import "./NavBar.css";

export default function NavBar() {
  return (
    <div className="navbar">
      <div className="content flex items-center ml-auto mr-auto">
        <div className="logo">Sharing is Caring</div>
      </div>
    </div>
  );
}
