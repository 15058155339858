import { createContext } from "react";

export type ResultType = string | number | boolean | string[] | number[];

export const ResultContext = createContext<{
  setResult: (result: Record<number, ResultType>) => void;
  result: Record<number, ResultType>;
}>({
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setResult: () => {},
  result: {},
});
