import React from "react";
import "./HorizontalNavItem.css";

export function HorizontalNavItem({
  active,
  text,
  onClick,
}: {
  active: boolean;
  text: string;
  onClick: () => void;
}) {
  return (
    <div className="nav-item pointer" onClick={onClick}>
      <div className={`status ${active ? "active" : ""}`} />
      <div className="text">{text}</div>
    </div>
  );
}
