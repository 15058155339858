import React, { useState } from "react";
import NavBar from "./components/NavBar";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { routes } from "./constants/Routes";
import { ResultContext } from "./ResultContext";
import { MainPage } from "./pages/MainPage";

export default function App() {
  const [results, setResults] = useState({});
  return (
    <>
      <ResultContext.Provider
        value={{
          setResult: (r) => {
            setResults(r);
          },
          result: results,
        }}
      >
        <BrowserRouter>
          <NavBar />
          <Routes>
            <Route path={routes.start} element={<MainPage />} />
          </Routes>
        </BrowserRouter>
      </ResultContext.Provider>
    </>
  );
}
