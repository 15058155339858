import React, { useContext, useEffect } from "react";
import { ResultContext } from "../ResultContext";
import { Label } from "../components/Label";

export function SingleInput({
  questionId,
  label,
}: {
  questionId: number;
  label: string | undefined;
}) {
  const { result, setResult } = useContext(ResultContext);

  const foundResult = (result[questionId] as string) || "";

  useEffect(() => {
    if (!result[questionId]) {
      const copy = { ...result };

      copy[questionId] = "";

      setResult(copy);
    }
    // eslint-disable-next-line
  }, [questionId]);

  return (
    <div className="flex flex-column">
      <Label
        title={label || ""}
        information={
          <>
            <div className="text-justify">
              Your legal company name is the name that you use on the paperwork
              when you incorporate your company.
            </div>
            <div className="mt-4 text-justify">
              Example: Google is the trade name or the name that the company is
              commonly known by, while its legal name is ‘Alphabet’.
            </div>
          </>
        }
      />
      <input
        type="text"
        placeholder={label}
        value={foundResult}
        onChange={(e) => {
          const copy = { ...result };

          copy[questionId] = e.target.value as string;

          setResult(copy);
        }}
      />
    </div>
  );
}
