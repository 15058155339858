export enum QuestionType {
  SingleInput = "SingleInput",
  SelectionInput = "SelectionInput",
}

export const Phases = [
  {
    id: 0,
    name: "Algemeen",
    position: 0,
  },
  {
    id: 1,
    name: "Gezondheid",
    position: 1,
  },
  {
    id: 2,
    name: "Welzijn en cultuur",
    position: 2,
  },
  {
    id: 3,
    name: "Internationale samenwerking",
    position: 3,
  },
  {
    id: 4,
    name: "Natuur, milieu en dierenbelangen",
    position: 4,
  },
];

export const GivenQuestions = [
  {
    id: 0,
    phase: 0,
    position: 0,
    question: "Question",
    questionType: QuestionType.SingleInput,
    label: "Label",
  },
  {
    id: 1,
    phase: 0,
    position: 1,
    question: "Question 1",
    questionType: QuestionType.SelectionInput,
    label: "Label",
    options: [
      "Item 1",
      "Item 2",
      "Item 3",
      "Item 4",
      "Item 5",
      "Item 6",
      "Item 7",
      "Item 8",
      "item 9",
      "Item 10",
    ],
  },
  {
    id: 2,
    phase: 0,
    position: 2,
    question: "Question 2",
    questionType: QuestionType.SingleInput,
    label: "Extra information",
  },
  {
    id: 3,
    phase: 1,
    position: 3,
    question: "Question 3",
    questionType: QuestionType.SingleInput,
    label: "Extra information",
  },
  {
    id: 4,
    phase: 2,
    position: 4,
    question: "Question 4",
    questionType: QuestionType.SingleInput,
    label: "Extra information",
  },
  {
    id: 5,
    phase: 3,
    position: 5,
    question: "Question 5",
    questionType: QuestionType.SingleInput,
    label: "Extra information",
  },
  {
    id: 6,
    phase: 4,
    position: 6,
    question: "Question 6",
    questionType: QuestionType.SingleInput,
    label: "Extra information",
  },
];
