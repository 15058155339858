import React from "react";
import "./Question.css";

export function Question({ title }: { title: string }) {
  return (
    <div className="question flex flex-column">
      <div className="title">{title}</div>
    </div>
  );
}
