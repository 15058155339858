import React from "react";
import { HorizontalNavItem } from "./HorizontalNavItem";
import "./HorizontalNav.css";
import { Phases } from "../constants/Questions";

export function HorizontalNav({
  selectedPhase,
  setSelectedPhase,
}: {
  selectedPhase: number;
  setSelectedPhase: (p: number) => void;
}) {
  const foundSelected = Phases.find((p) => p.id === selectedPhase);

  return (
    <div className="flex nav horizontal">
      {Phases.sort((a, b) => a.position - b.position).map((p, index) => {
        return (
          <HorizontalNavItem
            active={
              foundSelected !== undefined &&
              p.position <= foundSelected.position
            }
            text={p.name}
            onClick={() => {
              setSelectedPhase(p.id);
            }}
            key={index}
          />
        );
      })}
    </div>
  );
}
