import React, { ReactElement, useState } from "react";
import { RiQuestionLine } from "react-icons/ri";
import Dialog from "./Dialog";

export function Label({
  title,
  information,
  type,
}: {
  title: string;
  information: ReactElement;
  type?: "subQuestion";
}) {
  const [openInformation, setOpenInformation] = useState(false);

  return (
    <>
      <div
        className="flex pointer items-center"
        onClick={() => {
          setOpenInformation(true);
        }}
      >
        <div className={type === "subQuestion" ? "sub-question" : ""}>
          {title}
        </div>
        <RiQuestionLine className={"ml-2"} />
      </div>
      <Dialog
        isOpen={openInformation}
        onRequestClose={() => {
          setOpenInformation(false);
        }}
        title={title}
        size={"xsmall"}
        padding={true}
      >
        {information}
      </Dialog>
    </>
  );
}
